@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poller+One&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    background-color: #121415;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #121415;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #ff4081;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #ff4081;
}

.no-space {
    margin: 0;
    padding: 0;
}

.nav {
    color: #ff4081 !important;
    font-family: 'Lato', sans-serif !important;
}

.nav:hover {
    font-family: 'Lato', sans-serif !important;
    color: #ffffff !important;
}

a {
    font-family: 'Lato', sans-serif;
    color: #ff4081;
}

a:hover {
    font-family: 'Lato', sans-serif;
    color: #ffffff;
}

.text-dark {
    font-family: 'Lato', sans-serif;
    color: #ff4081 !important;
}

.text-dark:hover {
    font-family: 'Lato', sans-serif;
    color: #ffffff !important;
}

.first-screen {
    height: 100vh;
    background-image: url("../src/img/intro.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(20%);
    margin-top: -90px;
}

.prijava {
    height: 100vh;
    background-image: url("../src/img/vida-fitness.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
    margin-top: -90px;
}

.prijava-profil {
    height: 100vh;
    background-image: url("../src/img/c.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
    margin-top: -90px;
}

.prijava-profil-posle-logovanja {
    height: 100vh;
    background-image: url("../src/img/man.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
}

.ranglista {
    height: 150vh;
    background-image: url("../src/img/unnamed.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
}

.online {
    height: 100vh;
    background-image: url("../src/img/online.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
}

.forum {
    height: 100vh;
    background-image: url("../src/img/forum.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(80%);
}

.forumAdmin {
    height: 100vh;
    background-image: url("../src/img/forum.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(80%);
    margin-top: -90px;
}

.forumdetaljinje {
    height: 160vh;
    background-image: url("../src/img/forumdetaljinje.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(80%);
}

.pravila {
    height: 100vh;
    background-image: url("../src/img/fitness.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
}

.recepti {
    filter: grayscale(60%);
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.zadaci {
    height: 130vh;
    background-image: url("../src/img/Free-Man.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(60%);
}

input {
    width: 100%;
}

.text-left {
    font-weight: 800;
    color: #ff4081;
}

#languges {
    font-family: 'Lato', sans-serif;
    color: #ff4081;
    text-align: right;
    cursor: pointer;
    z-index: 1111111 !important;
}

#countdown-text {
    color: #ff4081;
    font-size: 3em;
    text-shadow: 2px 2px #000000;
    padding-top: 40%;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: 700;
}

#countdown {
    color: #ff4081;
    font-size: 3.5em;
    text-shadow: 2px 2px #000000;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: 900;
}

.scroll-down {
    position: absolute;
    left: 50%;
    bottom: 30px;
    display: block;
    text-align: center;
    font-size: 100px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 23px;
    height: 23px;
    border-bottom: 2px solid #ff4081;
    border-right: 2px solid #ff4081;
    z-index: 9;
    left: 50%;
    -webkit-transform: translate(-50%, 0%) rotate(45deg);
    -moz-transform: translate(-50%, 0%) rotate(45deg);
    transform: translate(-50%, 0%) rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation: fade_move_down 4s ease-in-out infinite;
    animation: fade_move_down 4s ease-in-out infinite;
}

.text-left-2 {
    color: whtie !important;
}

#error {
    color: #ff4081;
    text-align: center;
}

#textarea {
    width: 100%;
    height: 180px;
}

.saznaj {
    margin-left: 70%;
}

.center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.whitespan {
    color: white;
    margin-left: 2%;
}

.whiteRangList {
    color: white !important;
    font-weight: 700 !important;
}

.centerNav {
    text-align: center !important;
    font-size: 1.3em;
}

.x {
    cursor: pointer;
}

.rightX {
    float: right;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.leftAnketa {
    text-align: left !important;
    font-size: 1.2em;
    border-bottom: 1px solid #ff4081;
}

.pinkspan {
    color: #ff4081;
    font-weight: 600;
    font-size: 1.2em;
}

.xright {
    cursor: pointer;
    text-align: right;
    padding-right: 4%;
    color: #fff;
    border-bottom: 1px solid #ff4081;
    background-color: #ff4081;
}

.rightUser {
    color: #fff;
}

.pink {
    color: #ff4081 !important;
}

.pinkbutton {
    background-color: #ff4081;
    border: 2px solid #ffffff;
    color: #ffffff;
}

.profileBtn {
    background-color: #ff4081;
    border: 2px solid #ffffff;
    color: #ffffff;
}

input[type="file"] {
    color: #ffffff;
    background-color: #ff4081 !important;
}

.white {
    color: #ffffff;
}


/*animated scroll arrow animation*/

@-webkit-keyframes fade_move_down {
    0% {
        -webkit-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@-moz-keyframes fade_move_down {
    0% {
        -moz-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -moz-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@keyframes fade_move_down {
    0% {
        transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

.second-screen {
    background-color: #121415;
    border-top: 1px solid #ff4081;
}

section button {
    margin: 0 auto;
    width: 60%;
    font-size: 2.0rem;
    padding: 1.25rem 2.5rem;
    display: block;
    background-color: #121415;
    border: 1px solid #ff4081;
    color: #ff4081;
    font-weight: 300;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

section button:hover {
    background-color: #474747;
    color: #000000;
    border-color: #000000;
}

.intro {
    animation: slideInRight;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
    /* don't forget to set a duration! */
}

.tema {
    border: 1px solid #ff4081;
    padding: 1%;
}

.text {
    text-align: center;
    color: #ffffff;
}

.text-forum {
    text-align: left;
    color: #ffffff;
}

.text-forum-center {
    text-align: center;
    color: #ffffff;
}

.text-forum-saznaj {
    text-align: right;
    color: #ffffff;
}

.komentar-levo {
    border: 1px solid #ff4081;
    width: 50%;
    padding: 1%;
    cursor: alias;
}

.komentar-levo p {
    color: #ffffff;
    text-align: left;
}

.komentar-desno {
    border: 1px solid #ff4081;
    width: 50%;
    padding: 1%;
    float: right;
    cursor: alias;
}

.komentar-desno img {
    margin-left: 75%;
}

.komentar-desno p {
    color: #ffffff;
    text-align: right;
}

.drustvene-mreze {
    text-align: right;
    color: white;
    margin-top: 700%;
}

.chat-box {
    position: absolute;
    right: 20px;
    bottom: 0px;
    background: white;
    width: 300px;
    border-radius: 5px 5px 0px 0px;
}

.chat-head {
    width: inherit;
    height: 45px;
    background: #ff4081;
    border-radius: 5px 5px 0px 0px;
}

.chat-head h2 {
    color: white;
    padding: 8px;
    display: inline-block;
}

.chat-head img {
    cursor: pointer;
    float: right;
    width: 25px;
    margin: 0px;
}

.chat-body {
    height: 355px;
    width: inherit;
    overflow: auto;
    margin-bottom: 0px;
}

.chat-text {
    position: fixed;
    bottom: 0px;
    height: 55px;
    width: inherit;
}

.chat-text textarea {
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    border: 1px solid #bdc3c7;
    padding: 10px;
    resize: none;
}

.chat-text textarea:active,
.chat-text textarea:focus,
.chat-text textarea:hover {
    border-color: #ff4081;
}

.msg-send {
    background: #121415;
}

.msg-receive {
    background: #ff4081;
}

.msg-send,
.msg-receive {
    width: 200px;
    height: 35px;
    padding: 5px 5px 5px 10px;
    margin: 10px auto;
    border-radius: 3px;
    line-height: 30px;
    position: relative;
    color: white;
}

.msg-receive:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border: 15px solid;
    border-color: transparent #ff4081 transparent transparent;
    left: -29px;
    top: 5px;
}

.msg-send:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border: 15px solid;
    border-color: transparent transparent transparent #121415;
    right: -29px;
    top: 5px;
}

.msg-receive:hover,
.msg-send:hover {
    opacity: .9;
}

.bg-dark {
    background-color: #121415 !important;
    color: white !important;
}

.nav-item {
    color: white !important;
    border-right: 0px solid #ff4081;
}

.lista-jedan {
    color: white;
    border: 1px solid #ff4081;
}

.addOnProgram {
    background-color: white;
    border: 1px solid white;
    text-align: center;
    color: #ff4081;
    font-weight: 800;
    margin-top: 5%;
    cursor: pointer;
}

.centerProfile {
    text-align: center;
}

#korisnikSpan {
    color: #ff4081;
    font-weight: 800;
}

.flag {
    padding: 0.1%;
}

.centerTextRights {
    color: white;
    text-align: center;
    margin-top: 10%;
}

.paad {
    margin: 1%;
}

.flagPocetna {
    margin: 1%;
    margin-top: 10%;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .flag {
        margin-bottom: 4%;
    }

    .first-screen {
        height: 100vh;
        margin-top: -100px;
    }

    #countdown-text {
        padding-top: 10%;
    }

    .prijava-profil {
        margin-top: -100px;
    }
}