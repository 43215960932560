a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

.navbar-light .navbar-toggler {
    color: rgb(255, 64, 129);
    border-color: rgb(255 64 129 / 100%);
    background-color: rgb(255, 64, 129);
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}